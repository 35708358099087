<template>
  <div class="box">
    <div class="navigation">
      <div>{{ $parent.switchLanguage == 'zh' ? '联系我们' : 'Contact Us' }}</div>
      <span>></span>
      <div>{{ $parent.switchLanguage == 'zh' ? '合力新闻' : 'HELI News' }}</div>
      <span>></span>
      <div class="color">{{ $parent.switchLanguage == 'zh' ? '新闻详情' : 'News detail' }}</div>
    </div>
    <div class="container">
      <h1>{{ obj.title }}</h1>
      <div class="time">{{ obj.time }}</div>
      <div class="text">
        <img :src="obj.image" alt="" />
        <div v-html="obj.content"></div>
      </div>
      <div class="footer">
        <div class="left" @click="lastUrl(lastObj.id)" v-if="lastFlag">
            {{ $parent.switchLanguage == 'zh' ? '上一篇' : 'Previous article' }}：{{ lastObj.title }}
        </div>
        <div class="right" @click="nextUrl(nextObj.id)" v-if="nextFlag">
            {{ $parent.switchLanguage == 'zh' ? '下一篇' : 'Next article' }}：{{ nextObj.title }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "regards",
  data() {
    return {
      switchLanguage: "",
      list: [],
      id: "",
      obj: {},
      lastObj: {},
      nextObj: {},
      lastFlag: false,
      nextFlag: false,
    };
  },
  mounted() {
    this.list = JSON.parse(localStorage.getItem("relationList"));
    this.id = this.$route.query.id;
    this.queryList(this.id);
    this.scrollToTop()
    console.log(this.obj);
  },
  beforeDestroy() {},
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0); // 水平滚动为0，垂直滚动为0
    },
    queryList(id) {
      this.obj = this.list.find((item) => item.id == id);
      if (id > 1) {
        let lastId = Number(id) - 1;
        this.lastObj = this.list.find((item) => item.id == lastId);
        this.lastFlag = true;
      } else {
        this.lastFlag = false;
      }
      if (id < 11) {
        let nextId = Number(id) + 1;
        this.nextObj = this.list.find((item) => item.id == nextId);
        this.nextFlag = true;
      } else {
        this.nextFlag = false;
      }
    },
    lastUrl(id) {
      this.obj = this.list.find((item) => item.id == id);
      this.queryList(id);
      this.scrollToTop()
    },
    nextUrl(id) {
      this.obj = this.list.find((item) => item.id == id);
      this.queryList(id);
      this.scrollToTop()
    },
  },
};
</script>

<style lang="scss" scoped>
.navigation {
  margin-top: 55px;
  width: 100%;
  height: 58px;
  font-size: 16px;
  padding-left: 18%;
  background: #f1f1f1;
  display: flex;
  align-items: center;
  color: #666666;
  span {
    margin: 2px 10px 0;
  }
  .color {
    color: #96c33b;
  
  }
}
.container {
  padding: 4.5% 18%;
  .time {
    color: #666;
    margin: 20px 0;
    font-size: 13px;
  }
  .text {
    font-size: 14px;
    line-height: 30px;
    color: #666;
    img {
      width: 100%;
    //   height: 360px;
    }
  }
  .footer {
    margin-top: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left {
      font-weight: bold;
      color: #000;
      font-size: 14px;
      //   float: left;
    }
    .right {
      font-weight: bold;
      color: #96c33b;
      font-size: 14px;
      //   float: right;
    }
  }
}
</style>
